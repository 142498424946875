import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Flex,
  Box,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure
} from '@chakra-ui/react'

import { OptionsIcon } from '../../components/Shared/Icons'
import formatDate from '../../utils/formatDate'
import { useMutateDaily } from '../../hooks/dailyActivities'
import { useMutateChecklist } from '../../hooks/checkVehicles'
import { useMutateChecklist as useMutateChecklistTools } from '../../hooks/checklistTools'
import { useMutateEPP } from '../../hooks/epp'

import DeleteModal from '../Shared/DeleteModal'
import StatusTag from '../Shared/StatusTag'

// Tabla con lista de actividades diarias
const DailyTable = ({ data, document }) => {
  const history = useHistory()
  const toast = useToast()
  const [selectedDaily, setSelectedDaily] = useState(null)
  const { mutateAsync: mutateDaily } = useMutateDaily('DELETE')
  const { mutateAsync: mutateChecklistVehicle } = useMutateChecklist('DELETE')
  const { mutateAsync: mutateChecklistTools } =
    useMutateChecklistTools('DELETE')
  const { mutateAsync: mutateChecklistEEPP } = useMutateEPP('DELETE')

  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  // Eliminar actividad diaria
  const handleDelete = (dailyId) => {
    if (document.toString() === '1') {
      mutateDaily(dailyId, {
        onSuccess: () => {
          toast({
            title: `Documento eliminado`,
            status: 'success',
            isClosable: true
          })
        },
        onError: () => {
          toast({
            title: `Error al eliminar documento`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
    if (document.toString() === '2') {
      mutateChecklistVehicle(dailyId, {
        onSuccess: () => {
          toast({
            title: `Documento eliminado`,
            status: 'success',
            isClosable: true
          })
        },
        onError: () => {
          toast({
            title: `Error al eliminar documento`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
    if (document.toString() === '3') {
      mutateChecklistTools(dailyId, {
        onSuccess: () => {
          toast({
            title: `Documento eliminado`,
            status: 'success',
            isClosable: true
          })
        },
        onError: () => {
          toast({
            title: `Error al eliminar documento`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }
    if (document.toString() === '4') {
      mutateChecklistEEPP(dailyId, {
        onSuccess: () => {
          toast({
            title: `Documento eliminado`,
            status: 'success',
            isClosable: true
          })
        },
        onError: () => {
          toast({
            title: `Error al eliminar documento`,
            status: 'error',
            isClosable: true
          })
        }
      })
    }

    closeDelete()
  }

  const handleClickDelete = (doc) => {
    setSelectedDaily(doc)
    onOpenDelete()
  }

  // Selecciona la ruta de redirección segun el tipo de documento seleccionado
  const handleClickEdit = (doc) => {
    let rute = ''
    if (document.toString() === '1') {
      rute = 'diario'
    }
    if (document.toString() === '2') {
      rute = 'check_vehiculo'
    }
    if (document.toString() === '3') {
      rute = 'check_herramientas'
    }
    if (document.toString() === '4') {
      rute = 'epp'
    }
    history.push(`/${rute}/${doc.id}`)
  }

  const handleDownload = (doc) => {
    window.open(doc.pdf)
  }

  return (
    <Flex bg={'#FFF'}>
      <Box w="100%">
        <TableContainer maxWidth={'100%'}>
          <Table variant="simple" size="sm">
            <Thead bg={'#F2F1FB'} sx={{ borderCollapse: 'separate' }}>
              <Tr>
                <Th color="#36355F" fontWeight={'normal'}>
                  Fecha
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Creado por
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Estado
                </Th>
                <Th color="#36355F" fontWeight={'normal'}>
                  Opciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((daily) => (
                <Tr>
                  <Td>
                    <Text
                      fontSize={['12px', '18px']}
                      fontWeight={'bold'}
                      overflowWrap="break-word"
                    >
                      {formatDate(daily.date_start || daily.created_at)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={['12px', '18px']}
                      fontWeight={'bold'}
                      overflowWrap="break-word"
                    >
                      {daily.users.name}
                    </Text>
                  </Td>
                  <Td>
                    <StatusTag status={'Completado'} />
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        leftIcon={
                          <Icon
                            fontSize="22"
                            as={OptionsIcon}
                            color="#374957"
                          />
                        }
                      />
                      <MenuList>
                        <MenuItem onClick={() => handleClickEdit(daily)}>
                          Editar
                        </MenuItem>
                        <MenuItem onClick={() => handleClickDelete(daily)}>
                          Eliminar
                        </MenuItem>
                        {daily.pdf && (
                          <MenuItem onClick={() => handleDownload(daily)}>
                            Descargar
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar la actividad diaria?"
          handleDelete={handleDelete}
          selected={selectedDaily}
        />
      )}
    </Flex>
  )
}

export default DailyTable
