import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Flex,
  HStack,
  Box,
  IconButton,
  Icon,
  Spacer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  useToast,
  Tag,
  TagLabel
} from '@chakra-ui/react'

import { MenuIcon, ArrowFowardIcon } from '../Shared/Icons'
import DeleteModal from '../Shared/DeleteModal'

// Módulo para la tarjeta de documento
const CertificateCard = ({ name, icon, serial, rute, mutate, data }) => {
  const history = useHistory()
  const toast = useToast()
  const { user } = useSelector((state) => state.auth)
  const {
    isOpen: openDelete,
    onOpen: onOpenDelete,
    onClose: closeDelete
  } = useDisclosure()

  const handleDocument = () => {
    history.push(rute)
  }

  // Eliminar documento
  const handleDelete = (document) => {
    mutate(document, {
      onSuccess: () => {
        toast({
          title: `Documento eliminado`,
          status: 'success',
          isClosable: true
        })
      },
      onError: () => {
        toast({
          title: `Error al eliminar documento`,
          status: 'error',
          isClosable: true
        })
      }
    })
    closeDelete()
  }

  const handleClickDelete = () => {
    onOpenDelete()
  }

  const handleDownload = () => {
    window.open(data.certificate_pdf)
  }

  // Etiqueta de estado
  const tag = (status) => {
    const colors = (state) => {
      if (state === 'CREADO') {
        return {
          bg: '#E9E5FE',
          color: '#5241AB'
        }
      }
      if (state === 'MODIFICADO') {
        return {
          bg: '#E5FDCB',
          color: '#8CBB59'
        }
      }
      return {
        bg: '#FFCCC1',
        color: '#FD876F'
      }
    }
    return (
      <Tag
        size="lg"
        borderRadius="full"
        variant="outline"
        bg={colors(status).bg}
        color={colors(status).color}
        boxShadow="none"
        fontWeight="800"
        textTransform="capitalize"
      >
        <TagLabel>{status}</TagLabel>
      </Tag>
    )
  }

  return (
    <Box
      w={['100%', '100%', '30%']}
      bg="#FFFFFF"
      border="2px"
      borderStyle="solid"
      borderRadius="lg"
      borderColor="#E6E6EF"
      pb={5}
    >
      <Flex spacing={10} align="left" pt="15px">
        <Menu>
          <MenuButton
            as={Button}
            bg="#FFFFFF"
            leftIcon={<Icon fontSize="22" as={MenuIcon} color="#374957" />}
          />
          <MenuList>
            <MenuItem onClick={handleDocument}>Editar</MenuItem>
            {user.id_role === 1 || user.id === data.created_by ? (
              <MenuItem onClick={handleClickDelete}>Eliminar</MenuItem>
            ) : null}
            {data.certificate_pdf && (
              <MenuItem onClick={handleDownload}>Descargar</MenuItem>
            )}
          </MenuList>
        </Menu>
        <Text fontSize="15px" fontWeight="bold" color="#36355F" pt={2}>
          <Icon mr="4" fontSize="22" as={icon} color="#36355F" />
          {name} {serial}
        </Text>
        <Spacer />
        <IconButton
          icon={<Icon fontSize="22" as={ArrowFowardIcon} color="#374957" />}
          bg="#FFFFFF"
          onClick={handleDocument}
        />
      </Flex>
      {/* <HStack spacing={2} pl={35}>
        <Text fontSize="12px" color="#36355F" fontWeight="bold" m={0}>
          Serial: {serial}
        </Text>
        {tag(data.status)}
      </HStack> */}
      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          onClose={closeDelete}
          message="¿Esta seguro de querer eliminar el documento?"
          handleDelete={handleDelete}
          selected={data}
        />
      )}
    </Box>
  )
}

export default CertificateCard
