import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getVisits,
  getOneVisit,
  createVisit,
  updateVisit,
  updateVisitStatus,
  deleteVisit,
  getWeeklyDocs
} from '../api/visits'

const getAction = (type) => {
  if (type === 'CREATE') return createVisit
  if (type === 'UPDATE') return updateVisit
  if (type === 'UPDATE-STATUS') return updateVisitStatus
  if (type === 'DELETE') return deleteVisit
  return null
}

export function useVisits(page, formData, props = {}) {
  return useQuery(['visits', page], () => getVisits(page, formData), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useVisit(visitId) {
  return useQuery(['visit', visitId], () => getOneVisit(visitId), {
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useMutateVisit(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('visit')
      queryClient.invalidateQueries('visits')
    },
    onError: (err) => {
      console.log(err.response)
    }
  })
}

export function useWeeklyDocs({ userId, plate }, props = {}) {
  return useQuery(['docs', userId, plate], () => getWeeklyDocs(userId, plate), {
    ...props,
    onError: (err) => {
      console.log(err.response)
    }
  })
}
