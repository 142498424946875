import axios from '../Axios'

export const getVisits = async (page = 1, formData, size = 10) => {
  const { data } = await axios.get(
    `visit/all?page=${page}&size=${size}${
      formData.startDate ? `&start_date=${formData.startDate}` : ''
    }${formData.endDate ? `&end_date=${formData.endDate}` : ''}${
      formData.clientId ? `&client_id=${formData.clientId}` : ''
    }`
  )
  return data
}

export const getOneVisit = async (visitId) => {
  const { data } = await axios.get(`visit/get-one?id_visit=${visitId}`)
  return data
}

export const createVisit = async (formData) => {
  const { data } = await axios.post(`visit/`, formData)
  return data
}

export const deleteVisit = async (visitId) => {
  const { data } = await axios.delete(`visit/?id=${visitId}`)
  return data
}

export const updateVisit = async (formData) => {
  const { data } = await axios.patch(`visit/`, formData)
  return data
}

export const updateVisitStatus = async (formData) => {
  const { data } = await axios.patch(`visit/update-status`, formData)
  return data
}

export const getWeeklyDocs = async (userId, plate) => {
  const { data } = await axios.get(`visit/weekly-docs`, {
    params: {
      id_user: userId,
      plate
    }
  })
  return data
}
