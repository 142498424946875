const CURRENTIP = 'localhost'

const config = {
  prod: {
    API_BASE: 'https://serfusapi.serfusanchile.cl/api/v1/',
    // API_BASE: 'https://serfusan-api-prod-xbv9e.ondigitalocean.app/api/v1/',
    API_BASE_TWO: 'https://serfusanapi-two.serfusanchile.cl/'
  },
  test: {
    API_BASE: 'https://serfusapi-test.serfusanchile.cl/api/v1/',
    // API_BASE: 'https://serfusan-api-testing-wei4v.ondigitalocean.app/api/v1/',
    API_BASE_TWO: `https://serfusanapi-two.serfusanchile.cl/`
  },
  dev: {
    API_BASE: `http://${CURRENTIP}/api/v1/`,
    API_BASE_TWO: `https://serfusanapi-two.serfusanchile.cl/`
  }
}

export default config
