import { useState } from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  Textarea,
  Switch,
  SimpleGrid,
  Stack,
  AspectRatio
} from '@chakra-ui/react'
import { DeleteIcon } from './Icons'
import _ from 'lodash'

const RenderImageVisitsContainer = ({
  images,
  haveComments = true,
  getNewArray = () => {},
  getNewArrayDelete = () => {}
}) => {
  const [checked, setChecked] = useState(false)
  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    let filename = img.substr(index)
    const ext = filename.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1] || '..'
    if (filename.length > 22) {
      filename = `${filename.slice(0, 20)}..`
    }

    return `${filename}${ext}`
  }

  //Actualiza los valores de la lista de imagenes
  const handleChangeImages = (event, position) => {
    const { name, value } = event.target
    const newArray = _.cloneDeep(images)
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    getNewArray(newArray)
  }

  const handleDeleteImages = (position) => {
    const newArray = _.cloneDeep(images)
    newArray.splice(position, 1)
    getNewArrayDelete(newArray)
  }

  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      gap={2}
      w="100%"
    >
      {images.map((img, idx) => (
        <SimpleGrid columns={2} w="100%" gap={2} key={img.url}>
          <Flex gap={2} justify="space-between" p={2}>
            <Stack>
              <Image
                src={img.url}
                alt="Imagen"
                height={200}
                w={200}
                onClick={() => window.open(img.url, '_blank')}
                cursor="pointer"
              />
              <Text>{getFilename(img.url)}</Text>
            </Stack>
            <IconButton
              icon={<Icon fontSize="22" as={DeleteIcon} />}
              onClick={() => handleDeleteImages(idx)}
            />
          </Flex>
          <Box
            w="100%"
            pb={5}
            borderRadius={'lg'}
            boxShadow={'md'}
            key={img.url}
          >
            <Flex gap={2} justify="space-between" p={2}>
              <HStack justify={'space-between'} w="100%">
                <Text fontSize="14px" fontWeight="bold" color="#36355F">
                  ¿Permitir agregar plan de acción?
                </Text>
                <Switch
                  size="md"
                  isChecked={checked}
                  onChange={() => setChecked(!checked)}
                  colorScheme={'cyan'}
                  name="monitored"
                />
              </HStack>
            </Flex>
            {checked && (
              <Flex gap={2} justify="space-between" p={2}>
                <FormControl id="action_plan">
                  <FormLabel>Plan de acción</FormLabel>
                  <Textarea
                    name="action_plan"
                    value={img.action_plan}
                    onChange={(event) => handleChangeImages(event, idx)}
                    placeholder="Plan de acción"
                  />
                </FormControl>
              </Flex>
            )}
            {haveComments && (
              <Flex gap={2} justify="space-between" p={2}>
                <FormControl id="observation">
                  <FormLabel>Observaciones</FormLabel>
                  <Textarea
                    name="observation"
                    value={img.observation}
                    onChange={(event) => handleChangeImages(event, idx)}
                    placeholder="Observaciones"
                  />
                </FormControl>
              </Flex>
            )}
          </Box>
        </SimpleGrid>
      ))}
    </Flex>
  )
}

export default RenderImageVisitsContainer
