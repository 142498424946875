import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useMutateVisit } from '../../hooks/visits'
import { useFormik } from 'formik'

const validationSchema = Yup.object().shape({
  area: Yup.string()
})

export default function VisitEdit({ visit }) {
  const { user } = useSelector((state) => state.auth)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: mutateVisit, isLoading: updateVisitIsLoading } =
    useMutateVisit('UPDATE')

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      area: visit.visit.area || '',
      created_by: user.id
    },
    onSubmit: async (values) => {
      await mutateVisit({ ...values, id: visit.visit.id })
      onClose()
    }
  })
  return (
    <>
      <Button variant="outline" colorScheme="teal" onClick={onOpen}>
        Editar
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar visita</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl
              id="area"
              isInvalid={formik.touched.area && Boolean(formik.errors.area)}
            >
              <FormLabel>Área del cliente</FormLabel>
              <Input
                name="area"
                value={formik.values.area}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Área del cliente"
              />
              <FormErrorMessage>
                {formik.touched.area && formik.errors.area}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              colorScheme="teal"
              ml={3}
              isLoading={updateVisitIsLoading}
              onClick={formik.handleSubmit}
            >
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
